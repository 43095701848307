import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vant';
import 'vant/lib/index.less';
import '@/styles/index.less';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
