const gameManageStore = {
  namespaced: true,
  state: {
    gameList: [],
    currentGame: {},
    currentServer: '',
    goodsList: [],
    fieldList: [],
  },
  mutations: {
    SET_GAME_LIST(state, gameList) {
      state.gameList = gameList;
    },
    SET_CURRENT_GAME(state, currentGame) {
      state.currentGame = currentGame;
    },
    SET_CURRENT_SERVER(state, currentServer) {
      state.currentServer = currentServer;
    },
    SET_GOODS_LIST(state, goodsList) {
      state.goodsList = goodsList;
    },
    SET_FIELD_LIST(state, fieldList) {
      state.fieldList = fieldList;
    },
  },
  actions: {
    setGameList({ commit }, gameList) {
      const data = gameList.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            selected: true,
          };
        }
        return {
          ...item,
          selected: false,
        };
      });
      commit('SET_GAME_LIST', data);
    },
    setCurrentGame({ commit, state }, { currentGame, isSort }) {
      state.gameList.forEach((item) => {
        item.selected = item.id === currentGame.id;
      });
      // 排序放在第一个
      if (isSort) {
        const data = state.gameList.sort((a) => {
          if (a.selected) {
            return -1;
          }
          return 1;
        });
        commit('SET_GAME_LIST', data);
      }

      commit('SET_CURRENT_GAME', currentGame);
    },
    setCurrentServer({ commit }, currentServer) {
      commit('SET_CURRENT_SERVER', currentServer);
    },
    setGoodsList({ commit }, goodsList) {
      const data = goodsList.map((item) => {
        return {
          ...item,
          count: 1,
        };
      });
      commit('SET_GOODS_LIST', data);
    },
    setFieldList({ commit }, fieldList) {
      commit('SET_FIELD_LIST', fieldList);
    },
    clearCart({ commit, state }) {
      const data = state.goodsList.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      commit('SET_GOODS_LIST', data);
    },
  },
};

export default gameManageStore;
