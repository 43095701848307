import { apiGetUserInfo } from '@/api/api';

const userInfoStore = {
  namespaced: true,
  state: {
    userInfo: {},
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      const res = await apiGetUserInfo();
      if (res.status === 200) {
        commit('SET_USER_INFO', res.data);
      }
    },
  },
};

export default userInfoStore;
