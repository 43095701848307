import axios from 'axios';
import { Toast } from 'vant';
import router from '@/router';

console.log('process.env.VUE_APP_API_BASE_URL', process.env.VUE_APP_API_BASE_URL);
const isDev = process.env.NODE_ENV === 'development';
// 创建axios实例
const instance = axios.create({
  baseURL: isDev ? '' : process.env.VUE_APP_API_BASE_URL,
  timeout: 120 * 1000, // 超时
  withCredentials: true, // 跨域请求时是否需要使用凭证
  headers: {
    'Content-Type': 'application/json charset=UTF-8',
  },
});

// 请求错误处理
const onError = (error) => {
  if (error.message.includes('timeout')) {
    Toast.fail('request timeout');
  }
  return error;
};

// 请求拦截
instance.interceptors.request.use((config) => {
  if (isDev) config.url = `/api${config.url}`;
  return {
    ...config,
  };
}, onError);

// 响应拦截
instance.interceptors.response.use((response) => {
  if (response && response.data) {
    return Promise.resolve(response);
  }
  return Promise.reject(new Error('response error'));
}, onError);

const request = async (config) => {
  try {
    const { data } = await instance.request(config);
    if (data.status === 200) {
      if (config.successToast) {
        Toast.success(data.msg);
      }
      return data;
    }
    if (data.status === 1007) {
      Toast.fail(data.msg);
      router.push('/login');
      return data;
    }
    if (config.errorToast) {
      Toast.fail(data.msg);
    }
    return data;
  } catch (err) {
    return {
      status: -1,
      msg: err.msg || 'request error',
      data: null,
    };
  }
};

/**
 *
 * @param url
 * @param params
 * @param successToast
 * @param errorToast
 */
export const getRequest = (url, params, successToast = false, errorToast = true) => {
  return request({ url, method: 'get', params, successToast, errorToast });
};

/**
 *  post接口默认提示成功和失败
 * @param url
 * @param params
 * @param successToast
 * @param errorToast
 */
export const postRequest = (url, params, successToast = false, errorToast = true, headers) => {
  return request({
    url,
    method: 'post',
    data: params,
    headers: headers || {},
    successToast,
    errorToast,
  });
};
