import { getRequest } from '@/utils/request';

// 获取验证码
export const apiGetVerifyCode = (params) => {
  return getRequest('/shopmall/game/get-verify.html', params, true);
};

// 登录
export const apiPhoneLogin = (params) => {
  return getRequest('/shopmall/game/login.html', params, true);
};

// 获取用户详情
export const apiGetUserInfo = (params) => {
  return getRequest('/shopmall/game/get-user-info.html', params);
};

// 退出登录
export const apiLogout = (params) => {
  return getRequest('/shopmall/game/login-out.html', params);
};

// 获取游戏列表
export const apiGetGameList = (params) => {
  return getRequest('/shopmall/game/get-game-list.html', params);
};

// 获取游戏详情
export const apiGetGameInfo = (params) => {
  return getRequest('/shopmall/game/get-game-detail.html', params);
};

// 下单
export const apiCreateOrder = (params) => {
  return getRequest('/shopmall/game/submit-order.html', params);
};

// 获取订单列表
export const apiGetOrderList = (params) => {
  return getRequest('/shopmall/game/get-order-list.html', params);
};

// 订单详情
export const apiGetOrderDetail = (params) => {
  // return getRequest('/shopmall/game/get-order-detail.html', params);
  return getRequest('/shopmall/game/get-order-list.html', params);
};

// 删除订单
export const apiDeleteOrder = (params) => {
  return getRequest('/shopmall/game/order-delete.html', params);
};
