import Vue from 'vue';

import { Button, Field, Popup, ActionSheet, Search, Stepper, Picker, CountDown, Tabs, Tab, Loading } from 'vant';

Vue.use(Button);
Vue.use(Field);
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(Stepper);
Vue.use(Picker);
Vue.use(CountDown);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Loading);
