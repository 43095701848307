import Vue from 'vue';
import Vuex from 'vuex';
import gameManageStore from '@/store/modules/gameManage';
import userInfoStore from '@/store/modules/userInfo';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    gameManageStore,
    userInfoStore,
  },
});
