import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/buy-goods',
    name: 'BuyGoods',
    component: () => import('@/views/buyGoods/index.vue'),
  },
  {
    path: '/personal-center',
    name: 'PersonalCenter',
    component: () => import('@/views/personalCenter/index.vue'),
  },
  {
    path: '/order-list',
    name: 'OrderList',
    component: () => import('@/views/orderManage/index.vue'),
  },
  {
    path: '/order-detail',
    name: 'OrderDetail',
    component: () => import('@/views/orderManage/orderDetail.vue'),
  },
  {
    path: '/pay-result',
    name: 'PayResult',
    component: () => import('@/views/payResult/index.vue'),
  },
];

export default new VueRouter({
  mode: 'hash',
  // base: '/',
  routes,
});
